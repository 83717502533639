import * as React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import 'maplibre-gl/dist/maplibre-gl.css';
import './App.css';
import Front from './Components/Front';
import Getinfo from './Components/Getinfo';
import Inedx from './smrt/Inedx';
import Ep from './Components/map/Ep';
import Epnsk from './Components/map/Epnsk';
import Sv from './Components/map/Sv';
import Sobor from './Components/map/Sobor';
import Sv_Makariy from './Components/map/sv_Makariy'; 
import Sv_Nikolay from './Components/map/sv_Nikolay';
import Sv_Innocent from './Components/map/sv_Innocent';
import Sv_Michail from './Components/map/sv_Michail';
import Sv_Ioann from './Components/map/sv_Ioann';
import Sv_Valentin from './Components/map/sv_Valentin';
import Sv_Iliya from './Components/map/sv_Iliya';
import Sv_Dimitry from './Components/map/sv_Dimitry';
import Sv_Domna from './Components/map/sv_Domna';



// import H from './Regios/H';
import Iskitim from './Components/map/Iskitim/Iskitim';
import Karasuk from './Components/map/Karasuk/Karasuk';
import Searchh from './st_html/SEARCH/Searchh';
import MainCarousel from './Carousel/MainCarousel';
import Baganskiy from '../src/ProjectOPV/Baganskiy';
import Severniy from '../src/ProjectOPV/Severniy';
import Kainsk from './Components/map/Kainsk/Kainsk';
import Chulimskiy from '../src/ProjectOPV/Chulimskiy';
import Barabinskiy from '../src/ProjectOPV/Barabinskiy';
import Bolotinskiy from '../src/ProjectOPV/Bolotinskiy';
import Chanovskiy from '../src/ProjectOPV/Chanovskiy';
import Cherepanovskiy from '../src/ProjectOPV/Cherepanovskiy';
import Chistoozerniy from '../src/ProjectOPV/Chistoozerniy';
import Dovolenskiy from '../src/ProjectOPV/Dovolenskiy';
import Iskitimskiy from '../src/ProjectOPV/Iskitimskiy';
import Karagatskiy from '../src/ProjectOPV/Karagatkiy';
import Karasukskiy from '../src/ProjectOPV/Karasukskiy';
import Kishtovskiy from '../src/ProjectOPV/Kishtovskiy';
import Kochenevskiy from '../src/ProjectOPV/Kochenevskiy';
import Kochkovskiy from '../src/ProjectOPV/Kochkovskiy';
import Kolivanskiy from '../src/ProjectOPV/Kolivanskiy';
import Krasnozerskiy from '../src/ProjectOPV/Krasnozerskiy'; 
import Kupinskiy from '../src/ProjectOPV/Kupinskiy';
import Kuybishevskiy from '../src/ProjectOPV/Kuybishevskiy';
import Maslyaninskiy from '../src/ProjectOPV/Maslyaninskiy';
import Moshkovskiy from '../src/ProjectOPV/Moshkovskiy';
import Novosibirskiy from '../src/ProjectOPV/Novosibirskiy';
import Ordinskiy from '../src/ProjectOPV/Ordinskiy';
import Suzunskiy from '../src/ProjectOPV/Suzunskiy';
import Tatarskiy from '../src/ProjectOPV/Tatarskiy';
import Toguchinskiy from '../src/ProjectOPV/Toguchinskiy';
import Ubinskiy from '../src/ProjectOPV/Ubinskiy';
import UstTatarskiy from '../src/ProjectOPV/UstTatarskiy';
import Vengerovskiy from '../src/ProjectOPV/Vengerovskiy';
import Zdvinskiy from '../src/ProjectOPV/Zdvinskiy';
import InformContact from './Contact/InformContact';
import InformContact1 from './Contact/InformContact1';
import Kraeved from './Kraeved/Kraeved';
import Cerkov_kraeved from './Kraeved/Cerkov_kraeved';
import Chaussky from './Kraeved/Chaussky';
import Alnevsk from './Kraeved/Alnevsk';
import Mitr_nikifor from './Kraeved/Mitr_nikifor';
import Zashiverskaya from './Kraeved/Zashiverskaya';
import Patriot from './Kraeved/Patriot';





function App() {
  return (
   <BrowserRouter>
    <Routes>
      <Route path='/Zdvinskiy' element={<Zdvinskiy/>}/>
      <Route path='/Vengerovskiy' element={<Vengerovskiy/>}/>
      <Route path='/UstTatarskiy' element={<UstTatarskiy/>}/>
      <Route path='/Ubinskiy' element={<Ubinskiy/>}/>
      <Route path='/Toguchinskiy' element={<Toguchinskiy/>}/>
      <Route path='/Tatarskiy' element={<Tatarskiy/>}/>
      <Route path='/Suzunskiy' element={<Suzunskiy/>}/>
      <Route path='/Severniy' element={<Severniy/>}/>
      <Route path='/Ordinskiy' element={<Ordinskiy/>}/>
      <Route path='/Novosibirskiy' element={<Novosibirskiy/>}/>
      <Route path='/Moshkovskiy' element={<Moshkovskiy/>}/>
      <Route path='/Maslyaninskiy' element={<Maslyaninskiy/>}/>
      <Route path='/Kuybishevskiy' element={<Kuybishevskiy/>}/>
      <Route path='/Kupinskiy' element={<Kupinskiy/>}/>
      <Route path='/Krasnozerskiy' element={<Krasnozerskiy/>}/>
      <Route path='/Kolivanskiy' element={<Kolivanskiy/>}/>
      <Route path='/Kochkovskiy' element={<Kochkovskiy/>}/>
      <Route path='/Kochenevskiy' element={<Kochenevskiy/>}/>
      <Route path='/Kishtovskiy' element={<Kishtovskiy/>}/>
      <Route path='/Karasukskiy' element={<Karasukskiy/>}/>
      <Route path='/Karagatskiy' element={<Karagatskiy/>}/>
      <Route path='/Iskitimskiy' element={<Iskitimskiy/>}/>
      <Route path='/Dovolenskiy' element={<Dovolenskiy/>}/>
      <Route path='/Chulimskiy' element={<Chulimskiy/>}/>
      <Route path='/Chistoozerniy' element={<Chistoozerniy/>}/>
      <Route path='/Cherepanovskiy' element={<Cherepanovskiy/>}/>
      <Route path='/Chanovskiy' element={<Chanovskiy/>}/>
      <Route path='/Bolotinskiy' element={<Bolotinskiy/>}/>
      <Route path='/Barabinskiy' element={<Barabinskiy/>}/>
      <Route path='/Baganskiy' element={<Baganskiy/>}/>
      <Route path='/Iskitimskiy' element={<Iskitimskiy/>}/>
      <Route path='/MapMain' element={<Searchh/>}/>
      <Route path='/Severniy' element={<Severniy/>}/>
      <Route path='/Chulimskiy' element={<Chulimskiy/>}/>
      <Route path='' element={<Front/>}/>
      <Route path='/Mainmap' element={<Inedx/>}/>
      <Route path='/getinfo' element={<Getinfo/>}/>
      <Route path='/ep' element={<Ep/>}/>
      <Route path='/epnsk' element={<Epnsk/>}/>
      <Route path='/sv' element={<Sv/>}/>
      <Route path='/sobor' element={<Sobor/>}/>  
      <Route path='/sv_Makariy' element={<Sv_Makariy/>}/>
      <Route path='/sv_Nikolay' element={<Sv_Nikolay/>}/>
      <Route path='/sv_Innocent' element={<Sv_Innocent/>}/>
      <Route path='/sv_Michail' element={<Sv_Michail/>}/>
      <Route path='/sv_Ioann' element={<Sv_Ioann/>}/>
      <Route path='/sv_Valentin' element={<Sv_Valentin/>}/>
      <Route path='/sv_Iliya' element={<Sv_Iliya/>}/>
      <Route path='/sv_Dimitry' element={<Sv_Dimitry/>}/>
      <Route path='/sv_Domna' element={<Sv_Domna/>}/>


      <Route path='/Kraeved' element={<Kraeved/>}/>
      <Route path='/Cerkov_kraeved' element={<Cerkov_kraeved/>}/>
      <Route path='/Chaussky' element={<Chaussky/>}/>
      <Route path='/Alnevsk' element={<Alnevsk/>}/>
      <Route path='/Mitr_nikifor' element={<Mitr_nikifor/>}/>
      <Route path='/Zashiverskaya' element={<Zashiverskaya/>}/>
      <Route path='/Patriot' element={<Patriot/>}/>


      


      <Route path='/Contact' element={<InformContact/>}/>
      <Route path='/Contact1' element={<InformContact1/>}/>
      <Route path='/kainsk' element={<Kainsk/>}/>
      <Route path='/Carousel' element={<MainCarousel/>}/>
      <Route path='/Karasuk' element={<Karasuk/>}/>
      <Route path='/Iskitim' element={<Iskitim/>}/>
      
    </Routes> 
   </BrowserRouter>
  );
}

export default App;
