import React from "react";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import ikona from './img/kraeved2.jpg'; 
import chauss from './img/Chauss.jpg'; 
import zashiversk from './img/cerkov_zashiverskay.jpg';
import al_nev from './img/al_nev/hram5.jpg'; 
import z1 from './img/zashiversk.jpg'; 
import mitr from './img/mitr/Nikifor2.jpg'; 
import vozn from './img/vozn.jpg'; 








<meta name="viewport" content="user-scalable=yes"/>

function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Материалы по церковному краеведению</b>
        </div><p></p> 
      
        
      
                    
                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Cerkov_kraeved"> 
                    <div class="church-1-image"> <img src={ikona} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Cerkov_kraeved">
                          <div class="sv-title">
                          Церковное краеведение в нравственном воспитании человека</div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table>
<p></p>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Chaussky"> 
                    <div class="church-1-image"> <img src={chauss} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Chaussky">
                          <div class="sv-title">
                          Чаусский острог</div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table>
<p></p>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Alnevsk"> 
                    <div class="church-1-image"> <img src={al_nev} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Alnevsk">
                          <div class="sv-title">
                          Александро-Невский собор в Новосибирске </div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table>
<p></p>

                    {/* Митрополит Никифор */}
                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/mitr_nikifor"> 
                    <div class="church-1-image"> <img src={mitr} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/mitr_nikifor">
                          <div class="sv-title">
                          Первый Новосибирский архипастырь — 
                         митрополит Никифор (Асташевский)
 </div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table>
<p></p>


                 {/* Патриотическое служение Новосибирской епархии в годы войны */}
                 <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/patriot"> 
                    <div class="church-1-image"> <img src={vozn} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/patriot">
                          <div class="sv-title">
                          Патриотическое служение Новосибирской епархии в годы войны
 </div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table>
<p></p>



                    {/* Спасо-Зашиверская церковь */}
                    {/* <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/zashiverskaya"> 
                    <div class="church-1-image"> <img src={z1} width={260}
                                alt=""
                                title="" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/zashiverskaya">
                          <div class="sv-title">
                          Спасо-Зашиверская церковь
 </div>
                        <div class="inf-6">
                        <p>Читать</p></div>
                        </a></td></tr>
                   
                    </table> */}
<p></p>




                    <div class ={"inf-8"}>Раздел дополняется...</div> <p></p>
                    
                    <div class="image-podpis">
                        <img src={zashiversk} width={700} alt="Зашиверская церковь" />     
                       
                        <div class ={"inf-podpis"}>Спасо-Зашиверская церковь в Историко-архитектурном музее <div>под открытым небом 
                        Института археологии и этнографии СО РАН </div></div>                     
                    </div>
      
      <Link to='/'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
