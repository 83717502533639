import React from "react";

import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import hram6 from './img/mitr/cerkov.jpg';
import sem from './img/mitr/ds.jpg';
import arh from './img/mitr/arh_makariy.jpg';
import patr from './img/mitr/patr_i_makariy.jpg';
import mitr from './img/mitr/Nikifor2.jpg';
import mitr1 from './img/Nikifor.jpeg';
import mitrd from './img/mitr/mitr_duhovenstvo.jpg';
import tomb from './img/mitr/tomb.jpg';





function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b> Первый Новосибирский архипастырь — 
          <div>митрополит Никифор (Асташевский)</div>
 </b> 
        </div>  
        <div class ={"inf-5_podpis"}>
                        <div>Из книги протоиерея Бориса Пивоварова <div>
                          "Очерк истории Новосибирской епархии" </div> </div></div>

                 
                    <div> <table>
                        <div class="sv-title">
                           
                        </div>
                        
                        <div class="church-1-image">
                            <img src={mitr1} width={300}
                                alt=""
                                title="" />
                        </div>
                        <div class ={"inf-5"}>
                        Обозревая историю Ново-Николаевской–Новосибирской епархии, несомненно, нужно обратиться к жизнеописанию первого её архипастыря приснопамятного митрополита Никифора, а затем посмотреть — в какое время и при 
                        каких обстоятельствах ему довелось проходить своё служение на Новосибирской кафедре.</div>
                      
                       <div class ={"inf-5_2"}>Асташевский Николай Петрович родился 15 мая 1848 года в селе Курбатово Рязанской губернии в семье священника Петра Асташевского. В 1854 году иерей Петр по призыву епископа Томского и Енисейского Парфения (Попова) перешёл на служение в Томскую епархию. В 1864 году Николай поступил в Томскую духовную семинарию, где показал замечательные успехи в 
                       учёбе и благонравное поведение. </div>
                       <div class ={"inf-5_2"}>По окончании семинарии Николай Асташевский продолжил духовное образование в Казанской духовной академии, которую закончил в 1874 году со степенью кандидата богословия. В том же году он получил назначение в Томскую духовную семинарию, в которой стал преподавать философию, логику, психологию и дидактику. Доводилось ему обучать и греческому 
                       языку, когда не было соответствующего преподавателя. </div>
                       <div class ={"inf-5_2"}>При Томской духовной семинарии была воскресная школа, которую посещало множество учеников. В ней проводились занятия по Закону Божию, русскому языку, арифметике и пению. В преподавании участвовали воспитанники старших классов семинарии, а руководил ими Николай Петрович Асташевский. В 1889 году при семинарии была учреждена образцовая школа, в которой семинаристы проходили педагогическую практику. Обладая певческими дарованиями, Николай Петрович любил петь вместе с воспитанниками за богослужением 
                       в семинарском хоре, а иногда и руководил хором.  </div>
                       
                       <div class="church-1r1-image"><table className="table-w"><tr>
                            <img src={arh} width={300}
                                alt="Архиепископ Томский Макарий (Невский)"
                                title="Архиепископ Томский Макарий (Невский)" /></tr>
                        <tr><div class='podpis'>Архиепископ Томский Макарий (Невский)  
                        </div></tr></table></div>

                       <div class ={"inf-5_2"}>Педагогическая деятельность Н.П.Асташевского не ограничивалась стенами семинарии. В 1880–1897 годах он вёл занятия по педагогике в Мариинской женской гимназии города Томска. По благословению святителя Макария (Невского), который возглавлял Томскую кафедру с 1891 по 1912 год, он вместе с воспитанниками семинарии проводил занятия по дидактике в церковно-приходской школе при Томском архиерейском доме. В 1893 году эта школа была преобразована в двухклассную (пять лет обучения). 
                       В ней готовили учителей для других церковно-приходских школ Томской епархии. </div><p></p>

                      
                       <div class ={"inf-5_2"}>Находясь на церковно-педагогическом поприще в Томске, будущий новосибирский архипастырь приобрёл большую опытность не только как учитель и воспитатель, но и как распорядитель по административно-хозяйственной части. На время отсутствия ректора или инспектора семинарии Николай Петрович исполнял их обязанности, случалось это многократно. На протяжении более двадцати лет, 
                       с 1874 по 1897 год, он являлся членом Педагогического собрания семинарского Правления. </div>
                       
                       <div class ={"inf-5_2"}>Указом Святейшего Синода от 22 августа 1897 года Николай Петрович Асташевский был назначен на должность ректора недавно открытой Красноярской духовной семинарии. 18 сентября того же года он был рукоположен в сан священника в безбрачном состоянии. Хиротонию совершил святитель Макарий (Невский). Рукоположение состоялось в домовой церкви Томской семинарии в честь иконы Божией Матери 
                       «Живоносный Источник». Спустя три дня отец Николай Асташевский был возведён в сан протоиерея. </div>
                       
                       <div class ={"inf-5_2"}>25 октября 1897 года протоиерей Николай Асташевский прибыл в Красноярск. Будучи ректором Красноярской духовной семинарии, он был утверждён в должности председателя Енисейского епархиального училищного совета, избран членом Енисейского епархиального комитета Православного миссионерского общества, а также членом Енисейского отделения Императорского Православного Палестинского общества, избран председателем Правления Общества святого князя Александра Невского для вспомоществования нуждающимся учащимся в духовно-учебных заведениях города Красноярска, исполнял обязанности председателя Совета Братства Рождества Пресвятой Богородицы при кафедральном соборе в честь Рождества Пресвятой Богородицы Красноярска. Братство это занималось проведением внебогослужебных религиозно-нравственных чтений, организацией приходских библиотек, а также противодействием сектантам. С 1912 года отец Николай являлся 
                       председателем Правления Енисейского церковно-историко-археологического общества. </div>
                       
                       <div class ={"inf-5_2"}>В разные годы протоиерей Николай Асташевский преподавал в Красноярской духовной семинарии Библейскую историю, основное богословие, догматическое богословие, Священное Писание, немецкий язык и даже церковное пение. Особой заслугой ректора стало строительство новых каменных 
                       корпусов Красноярской семинарии. </div>
                       <p></p>

                       <div class="center-image"><table className="table-w"><tr>
                            <img src={sem} width={750}
                                alt="Красноярская духовная семинария"
                                title="Красноярская духовная семинария" /></tr>
                        <tr><div class='podpis'>Красноярская духовная семинария. Фото начала XX века  
                        </div></tr></table></div>


                       <div class ={"inf-5_2"}>В 1915 году деятельность Красноярской духовной семинарии проверял член Учебного комитета при Святейшем Синоде Пётр Фёдорович Полянский (в будущем — Митрополит Крутицкий Петр, Местоблюститель Патриаршего престола). Он дал очень высокую оценку трудам ректора как педагога и как администратора, отметив, что «его редкий, чрезвычайно высокий 
                       нравственный облик снискали ему любовь и как человека». </div>
                       
                        <div class ={"inf-5_2"}>В сентябре 1914 года протоиерей Николай Асташевский был назначен на должность ректора Тобольской духовной семинарии. Прослужив в Тобольске немногим более полугода, отец Николай определением Святейшего Синода от 5 мая 1915 года был уволен от духовно-учебной службы на покой. 
                        Его непрерывная церковно-педагогическая деятельность продолжалась более сорока лет!  </div>
                       
                        <div class ={"inf-5_2"}>За заслуги на ниве церковного просвещения будущий новосибирский архипастырь был награждён орденами св. Станислава III и II степеней, св. Анны III и II степеней, св. Владимира IV и III степеней, а также наперсным крестом из Кабинета 
                        Его Императорского Величества. </div>
                       
                        <div class ={"inf-5_2"}>После отъезда из Тобольска протоиерей Николай Асташевский проживал в Томске, где работал врачом его брат. В 1919 году, в период установления в Сибири власти Временного правительства Колчака, в Томске был сформирован Западно-Сибирский законоучительский союз, призванный объединить усилия преподавателей Закона Божия.
                        Председателем правления этого союза был избран протоиерей Николай Асташевский.</div>

                         <div class ={"inf-5_2"}>К сожалению, пока почти неизвестны страницы жизни первого новосибирского архипастыря с 1920 по 1923 год. Нет определённых данных и о времени его пострижения в монашество. Известно лишь, что при пострижении он получил имя в честь святителя Никифора, Патриарха Константинопольского. Господь хранил под кровом Своим будущего новосибирского архипастыря для дальнейших его трудов и подвигов на 
                        новой сибирской архиерейской кафедре. </div>

                        <div class="church-1l-image"><table className="table-w"><tr>
                            <img src={patr} width={460}
                                alt="Патриарх Тихон и митрополит Макарий"
                                title="Патриарх Тихон и митрополит Макарий" /></tr>
                        <tr><div class='podpis'>Патриарх Тихон и митрополит Макарий <div>с духовенством</div>  
                        </div></tr></table></div>

                        <div class ={"inf-5_2"}>22 августа 1924 года, незадолго до хиротонии архимандрита Никифора во епископа Ново-Николаевского, Святейший Патриарх Тихон посетил митрополита Макария (Невского), находящегося в изгнании в Николо-Угрешском монастыре под Москвой. Святитель Макарий хорошо знал по Томской семинарии преподавателя Николая Петровича Асташевского, которого 
                        сам и рукополагал в сан священника.</div>

                                               
                        <div class ={"inf-5_2"}>В последние годы своего святительства Патриарх Тихон часто совершал епископские хиротонии, отечески заботясь о том, чтобы православная паства России не оставалась без архипастырского окормления. Одной из таких хиротоний стало возведение 
                        в 1924 году архимандрита Никифора (Асташевского) во епископа Ново-Николаевского. </div>
                    
                        <div class ={"inf-5_2"}>25 сентября 1924 года в Москве Святейший Патриарх Московский и всея России Тихон совершил хиротонию архимандрита Никифора (Асташевского) во епископа Ново-Николаевского. Этот день можно считать днём рождения самостоятельной Ново-Николаевской — с 1926 года Новосибирской епархии. С этого времени и 
                        начинается летопись Новосибирской епархии. </div>
<p></p>
                        

                        <div class ={"inf-5_2"}>По преданию, которое бережно хранится священнослужителями епархии, к учреждению в Ново-Николаевске самостоятельной епархии имел непосредственное отношение славный служитель Церкви Божией — протоиерей Александр Поспелов (ок. 1882–1969). В 1924 году он предпринял поездку в Москву и от имени ново-николаевских священнослужителей и мирян просил Святейшего 
                        Патриарха Тихона о назначении в Ново-Николаевск архиерея.</div>

                        <div class ={"inf-5_2"}>25 сентября 1924 года в Москве, в храме святого мученика Иоанна Воина на Якиманке Святейший Патриарх Московский и всея России Тихон совершил хиротонию архимандрита Никифора (Асташевского) во епископа Ново-Николаевского. Этот день можно считать днём рождения 
                        самостоятельной Ново-Николаевской — с 1926 года Новосибирской епархии. </div>

                        <div class ={"inf-5_2"}>В послереволюционное время при поддержке безбожных властей стало набирать обороты обновленчество. Активными действиями обновленцы отметились в Сибири. В июне 1922 года в Томске состоялось собрание обновленческого духовенства для избрания «Временного церковного управления» (ВЦУ), которое вскоре было преобразовано в «Сибирское церковное управление» (СибЦУ). Это противоканоническое «управление» объявило, что его власть будет распространяться на территории Ново-Николаевской, Алтайской, Омской, Тобольской и 
                        ещё шести сибирских губерний.</div>
                        
                        <div class ={"inf-5_2"}>В июле 1922 года в Ново-Николаевске состоялось собрание приходских советов города, которое явной поддержки обновленческим «реконструкциям» не выразило. Имеется свидетельство, что в августе 1922 года в храмах Ново-Николаевска на богослужении возносилось имя Святейшего Патриарха Тихона. Тем не менее к 1923 году обновленцы при содействии 
                        безбожной власти захватили большинство сибирских епархий и приходов.</div>
                       

                        <div class="church-1r1-image"><table className="table-w"><tr>
                            <img src={hram6} width={370}
                                alt="Вознесенская церковь"
                                title="Вознесенская церковь" /></tr>
                        <tr><div class='podpis'>Вознесенская церковь. Фотография 1940 г.  
                          </div></tr></table></div>
                        
                        <div class ={"inf-5_2"}>Ко времени, когда епископ Ново-Николаевский Никифор прибыл в свой кафедральный град, Александро-Невский собор был уже захвачен обновленцами. Владыка Никифор стал служить в Вознесенской церкви, 
                        ставшей впоследствии кафедральным собором новосибирских архипастырей.</div>

                        <div class ={"inf-5_2"}> Вознесенская церковь была однопрестольной. Для того чтобы все прихожане могли поместиться на богослужении, по благословению Владыки Никифора в 1925 году к ней был пристроен придел, освящённый во имя святителя Николая Чудотворца — небесного покровителя Владыки от купели крещения. В первые же годы святительства Владыки Никифора были открыты Успенская церковь на новом кладбище в Новосибирске (1925) 
                        и Свято-Никольский храм в Ново-Луговом (1926).</div>

                        <div class ={"inf-5_2"}>К концу 1925 года в Новосибирске верными Патриарху Тихону оставались общины Вознесенской, Воскресенской, Покровской церквей, Старо-Покровская община и община Успенской церкви. У обновленцев оставались Александро-Невский собор, Богородице-Казанская, Пророко-Данииловская церкви и часовня Святителя Николая. Значительное число сельских 
                        храмов на территории Новосибирского округа также были заняты раскольниками.</div>

                        <div class ={"inf-5_2"}>Все годы своего служения на Новосибирской кафедре Владыка Никифор вёл активную борьбу с обновленческим расколом, что способствовало возвращению в лоно Церкви многих священнослужителей и прихожан, а затем и возвращению храмов, 
                        захваченных обновленцами.</div>

                        <div class ={"inf-5_2"}>Сохранился доклад начальника 6-го отделения Секретного отдела ОГПУ Е.А.Тучкова «о состоянии группировок» и «о работе» с 1 ноября 1924 по 1 февраля 1925 года. В этом докладе в разделе «Обновленцы» сказано (стиль и орфография в документе сохранены): «Обновленцы в настоящее время находятся на положении группы, всеми средствами сопротивляющейся напирающей на неё тихоновщине. Напор последней настолько серьёзный, что перебежки обновленцев-попов под высокую руку Тихона за последнее время приняли опять несколько усиленный характер, так в Курской губернии перешло сразу целое благочиние, 
                        <b> в Ново-Николаевской губ. 19 церквей»</b>.</div>
                       
                        <div class="church-1l-image"><table className="table-w"><tr>
                            <img src={mitr} width={300}
                                alt="Митрополит Никифор"
                                title="Митрополит Никифор" /></tr>
                        <tr><div class='podpis'>Митрополит Никифор  
                        </div></tr></table></div>

                        <div class ={"inf-5_2"}>В 1927 году Владыка Никифор был возведён в сан архиепископа, а 18 апреля 1932 года — в сан митрополита. К началу 1928 года возглавляемая им Новосибирская епархия включала объединённые в 17 благочиннических округов 205 приходов, находящихся в Новосибирском, Барабинском, Барнаульском и Каменском округах Западно-Сибирского края. 
                        Богослужения совершали 197 священников и 5 диаконов.  </div>
                       
                        <div class ={"inf-5_2"}>Благодаря ревностному служению Владыки Никифора обновленчество в епархии пошло на убыль. Но начало 1930-х годов ознаменовалось усилением гонений на верных Русской Церкви мирян и активное духовенство. В Западно-Сибирском крае многие священнослужители, церковнослужители и миряне были арестованы, расстреляны или отправлены в ссылки и лагеря. Так, в 1932 году был арестован келейник Владыки Никифора архимандрит Сергий (Скрипальщиков), который был незримо связан с Владыкой издавна: отец Сергий учился в Красноярской духовной семинарии в период ректорства в ней протоиерея Николая Асташевского. 
                        Он был приговорён к трём годам исправительно-трудовых лагерей. </div>

                        <div class ={"inf-5_2"}>Репрессии против духовенства и мирян сопровождались массовым закрытием храмов. Всего, по данным советских органов, в 1931–1937 годах в 
                        Западно-Сибирском крае было закрыто 185 церквей.</div>

                        <div class ={"inf-5_2"}>Все эти свидетельства важно знать для понимания того, в каких условиях Владыка Никифор нёс в Новосибирске крест своего архипастырского 
                        исповеднического служения Церкви Божией.</div>

                       

                        <div class ={"inf-5_2"}>12 марта 1934 года было принято Постановление Заместителя Патриаршего Местоблюстителя и Временного при нём Патриаршего Священного Синода, согласно которому полномочия областного архиерея предоставлены Преосвященным архиереям главных городов областей. 
                        В Западно-Сибирском крае это был город Новосибирск. </div>


                        <div class ={"inf-5_2"}>Митрополит Никифор управлял Новосибирской епархией до мая 1935 года. Находясь на покое, он оставался в Новосибирске и как мог помогал своему преемнику — архиепископу Сергию (Василькову), 
                        служившему ранее в Томске.</div>
                        <p></p>
                        <div class="center-image"><table className="table-w"><tr>
                            <img src={mitrd} width={750}
                                alt="Митрополит Никифор и архиепископ Сергий с духовенством"
                                title="Митрополит Никифор и архиепископ Сергий с духовенством" /></tr>
                        <tr><div class='podpis'>Митрополит Никифор и архиепископ Сергий с духовенством  
                        </div></tr></table></div>

                        <div class ={"inf-5_2"}>Скончался Владыка Никифор 30 апреля 1937 года — в Великую Пятницу, накануне Пасхи Христовой. Светлую Пасхальную Заутреню и Литургию в ночь с 1 на 2 мая совершил архиепископ Сергий. На следующий день, в Светлый Понедельник, при большом стечении народа (по воспоминаниям очевидцев — до четырёх тысяч человек) состоялись похороны первого новосибирского архипастыря. Погребён был митрополит Никифор на кладбище близ 
                        Успенской церкви Новосибирска.</div>


                        <div class ={"inf-5_2"}>Сразу же после погребения митрополита Никифора в Новосибирске поднялась новая волна репрессий и открытых гонений на православную веру и верных чад Русской Православной Церкви. Так, на следующий день после похорон Владыки Никифора были арестованы архиепископ Сергий (Васильков), архимандрит Сергий (Скрипальщиков), 
                        священники и миряне. Летом 1937 года они были расстреляны. </div>

                        <div class ={"inf-5_2"}>5 сентября 1962 года в связи с ликвидацией кладбища и сносом Успенской церкви останки митрополита 
                        Никифора были перенесены на Заельцовское кладбище Новосибирска и перезахоронены рядом с могилой Марии Дмитриевны, 
                        сестры митрополита Новосибирского и Барнаульского Варфоломея (Городцева). 
                        У православных жителей города есть возможность прийти на Заельцовское кладбище и почтить первого новосибирского
                         архипастыря за его великие труды, направленные на сохранение
                         веры православной и церковного единства в годы лихолетья. </div>


                        <div class ={"inf-5_2"}>В сентябре 2019 года на родине Владыки Никифора — в селе Курбатово Кораблинского района Рязанской области — была установлена памятная доска с надписью: «В сем святем храме Рождества Пресвятой Богородицы принял крещение уроженец с. Курбатово, выдающийся иерарх Русской Православной Церкви Никифор (Асташевский), 
                        митрополит Новосибирский и Барнаульский (1848–1937)». </div>
                        <div class ={"inf-5_2"}>Вечная память незабвенному Владыке Никифору! </div>
                        
                        

                        <p></p>
                        <div class="center-image"><table className="table-w"><tr>
                            <img src={tomb} width={600}
                                alt="Могила митрополита Никифора (Асташевского)"
                                title="Могила митрополита Никифора (Асташевского)" /></tr>
                        <tr><div class='podpis'>Могила митрополита Никифора (Асташевского) 
                        <div>на Заельцовском кладище Новосибирска</div>  
                        </div></tr></table></div>
                        
                        
                        </table>
                        
                          
                    
                    </div>        
      


             
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
