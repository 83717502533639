import React from "react";

import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import hram6 from './img/mitr/cerkov.jpg';
import sem from './img/patr/doc.jpg';
import varfolomei from './img/patr/varfolomei.jpg';
import dimitry_don from './img/patr/dimitry_don_.jpg';
import mitr from './img/patr/m_sergiy.jpg';
import hram_u from './img/patr/uspenskaya.jpg';
import s_luka from './img/patr/sv_luka2.jpg';
import doc2 from './img/patr/doc2.jpg';
import m_nikodim from './img/patr/vl_nikodim.jpg';






function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b> Патриотическое служение Новосибирской епархии <div>в годы Великой Отечественной войны
  </div>
        
 </b> 
        </div>  
       
        <div class ={"inf-5_podpis"}>
                        <div>Из книги протоиерея Бориса Пивоварова <div>
                          "Очерк истории Новосибирской епархии" </div> </div></div>
                 
                    <div> <table>
                        <div class="sv-title">
                           
                        </div>
                        
                       
                        <div class ={"inf-5_2"}>
                        XX век даровал многострадальной Русской Православной Церкви
 как прекрасный плод стояния за Веру Святую Православную
 в страшные годы гонений великий сонм новомучеников и исповедников. 
 Молитвами Богородицы и всех святых, в земле Русской просиявших, 
 Всемилостивый Господь преклонил суд об Отечестве нашем
 на милость. </div>
                     <div class="church-1-image"><table className="table-w"><tr>
                            <p></p><img src={mitr} width={350}
                                alt="Митрополит Сергий (Страгородский)"
                                title="Митрополит Сергий (Страгородский)" /></tr>
                        <tr><div class='podpis'>Митрополит Сергий (Страгородский)
                        <div>печатает послание к верующим</div>  
                        </div></tr></table></div>
 <div class ={"inf-5_2"}>В первый же день Великой Отечественной войны Местоблюститель
 Патриаршего престола Митрополит Московский (с 1943 г. — Патриарх
  Московский и всея Руси) Сергий (Страгородский), на которого
 в тяжелейшее для страны время было возложено руководство Церковью, 
 составил и собственноручно отпечатал на пишущей машинке
 послание к пастырям и пасомым Христовой Православной Церкви.
 В этом послании он обратился к верующим людям и вообще ко всем
 своим соотечественникам со словами:</div>
                      
                       <div class ={"inf-5_2"}> «Фашиствующие разбойники напали на нашу Родину… 
                       Но не первый раз приходится русскому народу выдерживать такие испытания.
 С Божией помощью и на сей раз он развеет в прах фашистскую вражескую силу.
  Наши предки не падали духом и при худшем положении, потому что 
  помнили не о личных опасностях и выгодах, а о
 священном своём долге перед Родиной и верой и выходили победителями.  </div>
                       <div class ={"inf-5_2"}> Не посрамим же их славного имени и мы — православные, родные
 им и по плоти и по вере. Отечество защищается оружием и общим народным подвигом, 
 общей готовностью послужить Отечеству в тяжкий
 час испытания всем, чем каждый может. Тут есть дело рабочим,
 крестьянам, учёным, женщинам и мужчинам, юношам и старикам.
 Всякий может и должен внести в общий подвиг свою долю труда, заботы
  и искусства… Если кому, то именно нам нужно помнить заповедь
 Христову: „Больши сея любве никтоже имать, да кто душу свою положит за други своя“ (Ин. 15, 13).
  Душу свою полагает не только тот,
 кто будет убит на поле сражения за свой народ и его благо, но и всякий, кто жертвует собой, 
 своим здоровьем или выгодой ради Родины…</div>
                       <div class ={"inf-5_2"}> Церковь Христова благословляет
                        всех православных на защиту
 священных границ нашей Родины. 
 <b> Господь нам дарует Победу!</b>» </div>
                       
                       

                       <div class ={"inf-5_2"}>Православные люди Новосибирска горячо
                        откликнулись на  призыв Митрополита Сергия —  «послужить Отечеству в тяжкий
 час испытания всем, чем каждый может». </div><p></p>

                    
                       <div class ={"inf-5_2"}> В единственной в Новосибирске
 действующей Успенской кладбищенской церкви регулярно совершались богослужения, читались
 воззвания Митрополита Сергия, зовущие русский православный народ
                        <div class="church-1-image"><table className="table-w"><tr>
                            <p></p><img src={hram_u} width={400}
                                alt="Успенская церковь в Новосибирске"
                                title="Успенская церковь в Новосибирске" /></tr>
                        <tr><div class='podpis'>Успенская церковь в Новосибирске 
                        <div></div>  
                        </div></tr></table></div>
 на защиту Отечества. Здесь же собирались денежные пожертвования в
 фонд обороны, тёплые вещи для отправки на фронт, продукты для раненых,
  находившихся в многочисленных новосибирских госпиталях.
 Священники и прихожане усердно молились о даровании победы нашему воинству, 
 о сохранении жизни воинов, а также о упокоении тех,
 кто положил свою жизнь за Родину.
 </div>
                       
                       <div class ={"inf-5_2"}> Одной из распространённых форм помощи фронту 
                       стала подписка
 на Государственный военный заём. 4 июня 1943 года в Москве вышло
 постановление о выпуске 2-го Государственного военного займа, а уже
 5 июня приходской совет Успенского храма Новосибирска подписался
 на сумму 50 тыс. рублей.</div>
                       
                       <div class ={"inf-5_2"}>Новосибирск, как и другие крупные города Сибири, 
                       активно участвовал в помощи раненым воинам. Весной 1943 года для участия
 в окружной конференции хирургов госпиталей Сибирского военного
 округа, на которой они делились опытом работы тыловых госпиталей,
 был приглашён главный хирург красноярского эвакогоспиталя
 №1515, консультант всех госпиталей края архиепископ Красноярский 
 Лука (Войно-Ясенецкий; 1877–1961). Конференция, собрав
шая более 340 хирургов СибВО, проходила в Новосибирске с 24 по
 29 марта. Доклад Владыки Луки, посвящённый опыту лечения огнестрельного
  остеомиелита, был одним из основных. Этот программный
 40-минутный доклад, сделанный профессором 25 марта на вечернем
 заседании конференции, с восторгом был принят аудиторией.  </div>
                       
                       <div class ={"inf-5_2"}> </div>
                       <p></p>

                       <div class="center-image"><table className="table-w"><tr>
                            <img src={sem} width={1200}
                                alt="Программа окружной конференции хирургов госпиталей Сибирского военного округа"
                                title="Программа окружной конференции хирургов госпиталей Сибирского военного округа" /></tr>
                        <tr><div class='podpis'>Программа окружной конференции хирургов госпиталей 
                        Сибирского военного округа.<div>Новосибирск, 24–29 марта 1943 г. ГАНО. Ф. Д-29. Оп. 2. Д. 88.  </div>
                        <div>Первый в списке докладчиков — профессор Войно-Ясенецкий (Красноярск)</div>

                        </div></tr></table></div>

                        <div class="church-1-image"><table className="table-w"><tr>
                            <p></p><img src={s_luka} width={300}
                                alt="святитель Лука (Войно-Ясенецкий)"
                                title="святитель Лука (Войно-Ясенецкий)" /></tr>
                        <tr><div class='podpis'>Святитель Лука (Войно-Ясенецкий) 
                        <div></div>  
                        </div></tr></table></div>
                       
                       <div class ={"inf-5_2"}>  Некоторых участников конференции 
                       поразил даже внешний
 облик докладчика, его «одухотворённое лицо, скромная одежда, 
 спокойные жесты». Во время перерыва
 многие пожелали подойти, представиться известному профессору-хирургу и поговорить с ним. </div>
                        
                        <div class ={"inf-5_2"}>Во время пребывания в Новосибирске архиепископ
                         Лука консультировал тяжелораненого фронтовика, 
                         сделал ему операцию, а также  провёл для собравшихся участников 
                         конференции показательную операцию, продемонстрировав ювелирную 
                         технику её выполнения.
 По свидетельству очевидцев, для того чтобы наблюдать за ходом ведения этой уникальной операции, 
 маститым хирургам, начальникам отделений пришлось даже забираться на стулья. Из Новосибирска
 Владыка Лука увозил признание сибиряков и «величайшую роскошь» военного времени — отданный
 ему в облздраве отрез парчи для архиерейского облачения, а также «все 
 принадлежности служителя», которых у него не было в Красноярске.  </div>
                        
                        
                        <div class ={"inf-5_2"}>24 августа 1943 года в Новосибирск прибыл 
                        архиепископ Новосибирский и Барнаульский Варфоломей, 
                        который стал активно восстанавливать в Сибири церковную жизнь.  </div>
                        <div class="church-1r1-image"><table className="table-w"><tr>
                            <p></p><img src={varfolomei} width={300}
                                alt="Архиепископ Варфоломей (Городцев)"
                                title="Архиепископ Варфоломей (Городцев)" /></tr>
                        <tr><div class='podpis'>Архиепископ Варфоломей (Городцев) 
                        <div></div>  
                        </div></tr></table></div>
                        <div class ={"inf-5_2"}>Одним из главных направлений его деятельности была забота
 о нуждах военного времени и прежде всего о сборе средств на укрепление армии, содержание госпиталей 
 и детских домов. На первом же богослужении, которое Владыка, как уже отмечалось, совершил 27 августа 
 1943 года в Успенской церкви, он призвал верующих оказывать
 всестороннюю помощь защитникам Отечества. Об этом сохранилась
 запись в его дневнике: «За Литургией я говорил проповедь о том, почему смерть 
 праведников — радостное событие (на слова 1-го ирмоса
 канона Успению Богоматери: „Преукрашенная Божественною славою, священная и славная, 
 Дево, память Твоя, вся собра к веселию верныя“), — и указывал, что смерть за Отечество, 
 за своих ближних является событием радостным, и поэтому ублажать должны мы наших
 воинов, проливающих кровь свою в этой ужасной войне». Впоследствии Владыка неоднократно обращался
  к верующим с призывом жертвовать фронту. В своём дневнике он писал: «1 (14 н.ст.) октября
 Покров Пресвятой Богородицы. Служил и Всенощное бдение (с акафистом Покрову Пресвятой Богородицы),
  и Литургию. За Всенощным бдением… призывал к пожертвованиям на подарки нашим воинам». </div>

                         <div class ={"inf-5_2"}> По призыву своего архипастыря духовенство и миряне 
                         новосибирских приходов стали регулярно собирать средства в Фонд обороны, в
 Фонд Красного Креста, во Всецерковный фонд помощи детям и семьям
 бойцов Красной армии, в Фонд помощи инвалидам и раненым Отечественной войны, а также 
 приобретать облигации военных займов, собирать подарки и тёплые вещи воинам — защитникам Отечества. </div>

 <div class="church-1-image"><table className="table-w"><tr>
                           <p></p> <img src={hram6} width={350}
                                alt="Вознесенская церковь"
                                title="Вознесенская церковь" /></tr>
                        <tr><div class='podpis'>Вознесенская церковь г. Новосибирска 
                        <div>Фотография 1940-х гг.</div>  
                          </div></tr></table></div>

                        <div class ={"inf-5_2"}>
В марте, мае и декабре 1944 года в Москву на имя И.В.Сталина архиепископом 
Варфоломеем были направлены телеграммы, в которых
 сообщалось о пожертвованиях, сделанных приходами двух церквей,
 действующих на территории Новосибирской области. Так, в телеграмме, 
 отправленной на имя Верховного Главнокомандующего
 29 мая, Владыка Варфоломей сообщал:
 

  «Православные верующие
 и духовенство г. Новосибирска к 1 мая собрали от вновь открытой Вознесенской 
 церкви 100 тысяч рублей на подарки бойцам Красной
 армии, а от Успенской церкви — 130 тысяч рублей Красному Кресту
 для оказания помощи семьям красногвардейцев и инвалидов Отечественной войны. 
 Все деньги внесены в Госбанк». В ответном послании
 Сталин написал сибирскому архипастырю: «Прошу передать верующим и духовенству 
 г. Новосибирска, собравшим 100000 рублей на подарки бойцам Красной армии 
 и 130000 рублей в фонд помощи семьям
 красногвардейцев и инвалидов Отечественной войны, мой привет
 и благодарность Красной армии». </div>

                                               
                        <div class ={"inf-5_2"}>
                        В целом за 1944 год духовенством и верующими Новосибирска
 и Новосибирской области было собрано 826,5 тыс. рублей. Из них на
 подарки бойцам Красной армии — 120 тыс. рублей, в Фонд помощи
 инвалидам и раненым — 230 тыс. рублей, в Фонд помощи детям и
 семьям военнослужащих — 230 тыс. рублей, во всецерковный Фонд
 помощи детям и семьям фронтовиков — 146,5 тыс. рублей, в «копилку
 школьника» — 50 тыс. рублей, на постройку танковой колонны имени
 Димитрия Донского — 50 тыс. рублей. Кроме того, новосибирскими
 приходскими советами и духовенством были приобретены облигации
 3-го Государственного военного займа на сумму 200 тыс. рублей и билеты 
 4-й денежно-вещевой лотереи на 32 тыс. рублей.  </div>
                    
                        <div class ={"inf-5_2"}>
                        В 1945 году приходами новосибирских церквей в помощь фронту
 и тылу было перечислено деньгами 711,3 тыс. рублей и облигациями
 государственных займов 148,2 тыс. рублей. Подписка на 4-й Государственный 
 военный заём среди духовенства и из церковных сумм составила 241,6 тыс. рублей. </div>
<p></p>
<div class="center-image"><table className="table-w"><tr>
                            <img src={doc2} width={800}
                                alt="Из Информационного доклада о состоянии и деятельности Русской Православной 
                                Церкви"
                                title="Из Информационного доклада о состоянии и деятельности Русской Православной 
                                Церкви" /></tr>
                        <tr><div class='podpis'>
                          Из «Информационного доклада о состоянии и деятельности Русской Православной 
                          Церкви
                        <div>в Новосибирской области и г. Новосибирске за время с 1 октября 1944 г. 
                          по 1 января 1945 г.».</div>
                        <div>ГАНО. Ф. Р-1418. Оп.1. Д. 11. Л. 34</div>

                        </div></tr></table></div>

                        <div class ={"inf-5_2"}>
Всего же за годы войны «от двух только церквей г. Новосибирска
 внесено на нужды военного времени наличными деньгами 2546582
 рублей (1 млн 686 тыс. рублей от Вознесенской церкви и 860 тыс. 582
 рубля от Успенской церкви)». </div>

                        <div class ={"inf-5_2"}>
Все годы войны в новосибирских храмах служились молебны о даровании
 победы над врагом, в дни крупных побед на фронтах совершались 
 благодарственные молебны. В августе 1943 года, после разгрома
 немцев на Курской дуге, важнейшим событием Великой Отечественной 
 войны стало освобождение Харькова. 29 августа 1943 года Владыка 
 сделал запись в дневнике: «За Литургией, начавши речь на слова
 апостола „о всем благодарите“(1 Сол. 5, 18) и выяснивши учение церковное 
 о благодарности, я затем стал говорить о том, что у нас есть особое 
 побуждение к принесению благодарности Господу, давшему
 нашим воинам блестящую победу под Харьковом и возвратившему
 этот город снова в наше обладание, и призвал всех к выслушанию благодарственного 
 молебна Господу за эту благодатную помощь. Служением молебна 
 и провозглашением обычных многолетий, а также
 и вечной памяти убитым на войне воинам окончилась служба».  </div>

                        <div class ={"inf-5_2"}>
Во время войны Владыка Варфоломей неоднократно совершал поездки 
по обширной Новосибирской епархии, и во всех сибирских храмах, 
в которых он служил, также звучал его горячий призыв
 к усердным молитвам о даровании победы и к пожертвованиям
 средств на помощь фронту и тылу. Откликаясь на этот призыв, православные 
 верующие по всей Сибири проводили сбор средств на нужды военного времени. </div>
                        
                        <div class ={"inf-5_2"}>
В 1944 году секретарь Владыки иеромонах Никандр (Вольянников) 
сообщал в Москву о взносах, сделанных приходами сибирских
 церквей в первом полугодии: «Сбор средств проходит во всех церквах
 Сибири: верующие городов и сёл несут свою лепту на содержание танковой 
 колонны им. Димитрия Донского, в фонд помощи детям фронтовиков, 
 на подарки воинам Красной армии. Высокий патриотический
 подъём и горячие молитвы в церквях о даровании скорейшей победы
 над немецкими ордами, вторгшимися на нашу священную землю,
 видны, где бы мы ни находились: в Новосибирске, Бийске или же далёком 
 Иркутске. За первое полугодие собрано в церквах Сибири около
 2-х миллионов рублей на нужды военного времени. На первом месте
 идёт г. Новосибирск, где собрано и внесено вместе с подпиской на 3-й
 Государственный военный заём 700 тыс. рублей, на втором месте гг.
 Барнаул и Бийск, внёсшие каждый свыше 300 тыс. рублей, затем идут
 Омск и Красноярск, внёсшие каждый свыше 100 тыс. рублей. Также
 значительные средства собрали и внесли в Фонд обороны церкви Иркутска, 
 Петропавловска, Акмолинска и другие». В целом сбор средств
 на патриотические цели в сибирских церквях продолжался до конца
 1946 года.</div>
                     
                      <div class="center-image"><table className="table-w"><tr>
                      <p></p>   <img src={dimitry_don} width={1100}
                                alt="Танковая колонна Димитрий Донской"
                                title="Танковая колонна Димитрий Донской" /></tr>
                        <tr><div class='podpis'>
                        Танковая колонна Димитрий Донской
                        

                        </div></tr></table></div>

                        
                        <div class ={"inf-5_2"}>
По тем временам это были огромные средства. Они шли на строительство 
танковых колонн, авиаэскадрилий, боевых кораблей, а также
 на подарки воинам и оказание помощи детям-сиротам, семьям фронтовиков, 
 военнослужащим, вернувшимся с фронта. Духовное же величие этих материальных 
 вложений в Победу было неизмеримо, ведь
 жертвовали на нужды фронта и тыла те, кто сам порой находился
 в крайней нужде. Говоря о собираемых Церковью в годы войны пожертвованиях 
 верующих, Митрополит Московский Сергий отмечал:
 «Это были не жертвы каких-нибудь богатых людей, которые от многого 
 нечто уделяли на нужды войны, а это были жертвы простых богомольцев, 
 которые вносили обычную свою лепту, но с особым её
 назначением. Из этих случайных пожертвований составились миллионы; 
 наши богомольцы были исполнены желанием чем-нибудь
 прийти на помощь воинам, отдающим свою жизнь за Отечество».</div>

                        <div class ={"inf-5_2"}>
В День Победы, 9 мая 1945 года, Владыка Варфоломей отправил
 Святейшему Патриарху Московскому и всея Руси Алексию I поздравительную 
 телеграмму, в которой написал: «С первого дня войны с фашистами 
 Русская Православная Церковь усердно молилась Господу
 о даровании доблестной Красной армии полной победы над врагом.
 Поздравляю Ваше Святейшество с Днём Победы как нашего Первосвятителя 
 и первого молитвенника за нашу Святую Родину. Архиепископ
 Варфоломей».</div>

                        <div class ={"inf-5_2"}>
За патриотическое служение в годы войны глава Новосибирской
 епархии архиепископ Новосибирский и Барнаульский Варфоломей,
 благочинный церквей города Новосибирска секретарь Владыки архимандрит 
 Никандр (Вольянников) и настоятель Успенского храма протоиерей 
 Николай Сырнев 5 ноября 1946 года были награждены
 медалями «За доблестный труд в Великой Отечественной войне 1941
1945 гг.». А ещё в 1944 году медалью «За оборону Ленинграда» был
 награждён диакон Назаров, служивший псаломщиком в Вознесенской
 церкви, — за службу во время блокады Ленинграда в 4-м строительном 
 тресте Народного комиссариата авиационной промышленности
 в должности ответственного исполнителя. </div>

                        <div class ={"inf-5_2"}>
                        На фронте в рядах народного ополчения воевал и будущий 
                        епископ Новосибирский и Барнаульский Донат (Щёголев). 
                        Он был мобилизован в сентябре 1941 года, участвовал в боевых действиях, 
                        был тяжело ранен. Демобилизован в марте 1943 года. 
                        </div>

                        <div class ={"inf-5_2"}>
Будущий архиепископ Новосибирский и Барнаульский Павел
 (Голышев) в годы Великой Отечественной войны участвовал во французском 
 движении Сопротивления, духовно окормлял в концлагерях
 советских военнопленных, за что немецкими оккупационными властями 
 подвергался аресту. Был этапирован в Париж, но вскоре освобождён. 

                        </div>
                       
                     
                        <div class ={"inf-5_2"}>
                        Боевыми орденами и медалями были отмечены ратные подвиги
 представителей новосибирского духовенства: архимандрита Макария
 (Реморова; †1998), протоиерея Александра Смолкина (†2002), архимандрита 
 Нифонта (Глазова; †2004), протоиерея Алексия Осипова
 (†2004), старшего пономаря Александро-Невского собора Анатолия
 Алексеевича Михеева (†2007), схииеромонаха Иосифа (Бирюкова;
 †2018).   </div>
                       
                        <div class ={"inf-5_2"}> 
Память о Великой Отечественной войне, о всех защитниках нашего 
Отечества и тружениках тыла, проявивших беспримерное муже
ство и неиссякаемую веру в Победу, свято хранилась и ныне хранится
 в Новосибирской епархии. В храмах епархии всегда возносились
 и ныне возносятся молитвы о мире всего мира, о Богом хранимой
 стране нашей, о защитниках Отечества нашего. </div>

                        
 <div class="center-image"><table className="table-w"><tr>
                      <p></p>   <img src={m_nikodim} width={1000}
                                alt="Митрополит Никодим принимает участие в торжественном собрании, 
                                посвященном Дню Победы"
                                title="Митрополит Никодим принимает участие в торжественном собрании, 
                                посвященном Дню Победы" /></tr>
                        <tr><div class='podpis'>
                        Митрополит Никодим принимает участие в торжественном собрании, 
                        посвященном Дню Победы.<div> Новосибирск. 2024 г.</div>
                        

                        </div></tr></table></div>

 


                        <div class ={"inf-5_2"}> </div>
                        
                        

                     
                        
                        
                        </table>
                        
                          
                    
                    </div>        
      


             
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
